import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import { buildBreadcrumbsItem, collectionUrl } from '../helpers'
import styles from '../scss/page.scss'
import StickyShare from '../components/sticky-share/sticky-share'

class friendlyCitiesPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'The Best U.S. Cities to Be a Dog | Honest Paws®'
    this.pageUrl = this.props.location.href

    this.breadcrumbs = [
      buildBreadcrumbsItem('The Best U.S. Cities to Be a Dog', this.props.location.href)
    ]

    this.mostDogFriendlyCities = this.props.data.mostDogFriendlyCities.childImageSharp.fluid
    this.bestCitiesForDogs = this.props.data.bestCitiesForDogs.childImageSharp.fluid
    this.worstCitiesForDogs = this.props.data.worstCitiesForDogs.childImageSharp.fluid
    this.dogFriendlyRentals = this.props.data.dogFriendlyRentals.childImageSharp.fluid
    this.vetNearMe = this.props.data.vetNearMe.childImageSharp.fluid
    this.dogParksNearMe = this.props.data.dogParksNearMe.childImageSharp.fluid
    this.ogImage = this.props.data.ogImage.childImageSharp.fluid

    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'We ranked 50 cities to find the best and worst U.S. communities for your canine-pals. Read on to discover the most dog-friendly cities the U.S. has to offer!',
        image: this.ogImage
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url'  />
      ]
    }
  }

  componentDidMount() {
    !function(d,s,id){
      var js,fjs=d.getElementsByTagName(s)[0];
      if(!d.getElementById(id)){
        js=d.createElement(s);
        js.id=id;
        js.src="https://public.flourish.studio/resources/embed.js";
        fjs.parentNode.insertBefore(js,fjs);
      }
    }
    (document,"script","flourish-embed");
  }

  render() {
    return (
      <Layout containerClassName={`page page-most-dog-friendly-cities`} hideFooter={true} hideHeader={true}
              location={this.props.location} bodyClassName="chat-hidden" >
        <SEO { ...this.seo }>
          { this.seo.children ? this.seo.children.map(el => el) : '' }
        </SEO>
        <div className="page-header-top-placeholder"></div>
        <StickyShare shareDialogTitle={this.seo.meta.title} shareDialogText={this.seo.meta.description}/>

        <section className="page-title-and-summary page-header text-center px-4">
          <div className="row">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <h1 className="mb-0">The Best U.S. Cities to Be a Dog</h1>
              <div className="my-4">We ranked 50 cities to find the best and worst U.S. communities for your canine-pals. Read on to discover the most dog-friendly cities the U.S. has to offer!</div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container page-content-container">
            <div className="image-container mx-auto">
              <Img fluid={this.mostDogFriendlyCities} alt="Intro image for the best U.S. cities to be a dog"  />
            </div>
            <p><i>*This article has been updated as of January 22, 2021.&nbsp;</i></p>
            <p>Devoted pet parents across
            the U.S. often ponder the question: in which communities can our canine pals thrive and prosper most? So
            much of a dog’s overall happiness rests upon factors like dog-friendly establishments, abundant outdoor
            spaces, healthy interaction with other dogs, and ample access to veterinary care. Some cities allow Fido
            to live the American dream, while other cities leave much to be desired.&nbsp;</p>
            <p>With overall dog
            ownership and <a href="https://www.honestpaws.com/blog/animal-shelters/" rel="noreferrer"
                             target="_blank">pandemic pet adoptions</a> on the rise, finding communities that foster
            our dogs’ physical and mental health couldn’t be more timely. For this reason, the team at Honest Paws
            ranked 50 major cities around the U.S. against a set of dog-friendly criteria to uncover the best (and
            worst) cities to<b> </b>be a dog.&nbsp;</p>
            <p>Read on to see where your city lands!</p>
            <h2 id="h-methodology"><b>Methodology</b></h2>
            <p>To determine the best and worst cities to be a dog, we
            looked at the nine ranking factors below for each city. We assigned weights to each factor based on
            their level of contribution towards a dog’s quality of life. Lastly, we calculated the sum of the nine
            weighted factors, which gave us an overall city score for each city. The highest possible city score was
            50.</p>
            <p>The factors are listed below with their respective weights and source data:</p>
            <p><b>1. Number
            of Pet-Friendly Restaurants (Per 100K People)</b></p>
            <p><i>Weight:</i> 1.0</p>
            <p><i>Source:</i> Yelp</p>
            <p><b>2. Number of Pet-Friendly Breweries (Per 100K People)</b></p>
            <p><i>Weight:</i> 1.0</p><p>
            <i>Source:</i> Yelp</p>
            <p><b>3. Number of Pet-Friendly Hotels (Per 100K People)</b></p>
            <p>
            <i>Weight:</i> 1.0</p>
            <p><i>Source:</i> Yelp</p>
            <p><b>4. Number of Pet-Friendly Beaches (Per 100K
            People)</b></p>
            <p><i>Weight: </i>1.0</p>
            <p><i>Source:</i> Yelp</p>
            <p><b>5. Number of Pet-Friendly
            Rentals (Per 100K People)</b></p>
            <p><i>Weight:</i> 1.5</p>
            <p><i>Source:</i> Rent.com</p>
            <p><b>6. Number
            of Dog Parks (Per 100K People)</b></p>
            <p><i>Weight:</i> 1.5</p>
            <p><i>Source:</i> City Government
            Websites &amp; Yelp</p>
            <p><b>7. Number of Employed Veterinarians in the Metropolitan Statistical Area
            (Per 100K People)</b></p>
            <p><i>Weight:</i> 1.5</p>
            <p><i>Source:</i> U.S. Bureau of Labor Statistics</p>
            <p><b>8. Number of Hiking Trails (Per 100K People)</b></p>
            <p><i>Weight:</i> 0.5</p>
            <p>
            <i>Source:</i> HikingProject.com</p>
            <p><b>9. Stance on Breed-Specific Legislation (BSL) by State*</b></p>
            <p><i>Weight:</i> 1.0</p>
            <p><i>Source:</i> ASPCA
              *When a state is <b>against</b> <a
              href="https://www.aspca.org/animal-protection/public-policy/what-breed-specific-legislation"
              rel="noreferrer" target="_blank">breed-specific legislation</a> (BSL), this can mean less
            discrimination against certain breeds like Pitbulls, Rottweilers, and Mastiffs. Therefore, we gave a
            higher score to cities located in states that <b>prohibit BSL</b> at the state level (<b>meaning the
              state is against BSL and breed discrimination</b>). Cities located in states that do not prohibit BSL
            at the state level earned a lower factor score.
          </p>
            <div className="anchor-section" id="best-cities"></div>
            <h2><b>The Best U.S. Cities to Be a Dog</b></h2>
            <div className="image-container mx-auto">
              <Img fluid={this.bestCitiesForDogs} alt="U.S. map showing the 20 best cities to be a dog "  />
            </div>
            <p>One city, famous for its fido-friendly shopping villages and off-leash beaches, emerged as the number
              one best city to be a dog: Tampa, FL. The waterfront city has 16 dog parks, 52 pet-friendly breweries,
              and six <a href="https://www.rover.com/blog/top-4-dog-friendly-beaches-near-tampa/" rel="noreferrer"
                         target="_blank">dog-friendly beaches</a> nearby, with a few allowing dogs to frolic in the
              water and roam off-leash. Not too shabby for a population size of just over 385,000.&nbsp;</p>
            <p>Another Florida city, Orlando, lands the third highest spot on our list, boasting 376 pet-friendly
              restaurants, 128 pet-friendly hotels, and 530 employed veterinarians within its metropolitan
              statistical area. What’s more, Hillsborough County (Tampa) and Orange County (Orlando)
              have <b>not</b> enacted breed-specific legislation, meaning breeds like Pitbulls and Rottweilers
              are <b>not</b> discriminated against. </p>
            <p>With 16 dog-friendly beaches nearby and plenty of vitamin
            D, San Diego, CA ranks number six on our list. San Diego’s Imperial Beach is home to an <a
              href="https://www.surfdogevents.com/imperial-beach/" rel="noreferrer" target="_blank">annual surf dog
              competition</a><u>,</u> where talented pups catch waves either solo or with their owners and proceeds
            are donated to the San Diego Humane Society.&nbsp;</p>
            <p>Widely-known as the outdoorsy dog’s “heaven on
            earth,” Denver, CO ranks number seven on our list, with 130 hiking trails, 744 pet-friendly
            apartment/townhouse rentals, and 12 dog parks. Also, Denver’s Crawford Hotel and Hotel Monaco treat
            their canine guests to a five-star experience, offering <a
              href="https://www.denver.org/about-denver/denver-resources/dog-friendly/" rel="noreferrer"
              target="_blank">luxury amenities</a> like dog beds, “pawdicures”, and even Furbo dog cameras.</p>
            <p>Cities like Austin, TX (#15) and Atlanta, GA (#19) make the top 20 due to their high concentration of
              pet-friendly apartments, townhouses, and homes for rent. In fact, Austin has 2,303 pet-friendly rental
              property listings – the highest of any other city in our ranking.
            </p>
            <div className="anchor-section" id="worst-cities"></div>
            <h2><b>The Worst U.S. Cities to Be a Dog</b></h2>

            <div className="image-container mx-auto">
              <Img fluid={this.worstCitiesForDogs} alt="U.S. map showing the 10 worst cities to be a dog"  />
            </div>
            <p>Indianapolis, IN, with a relatively low number of dog parks, pet-friendly restaurants, and
              pet-friendly hotels in respect to its population size, is the least ideal city to be a dog in the U.S.
              according to our ranking.&nbsp;</p>
            <p>Other cities that landed in the bottom ten include Louisville,
            KY (#2) where dog-friendly establishments like hotels, breweries, and restaurants are fewer and far
            between and Milwaukee, WI (#3), which restricts both Pitbulls and Rottweilers due to breed-specific
            legislation. New York City lands the number four spot on our list of worst cities to be a dog, largely
            because of poor access to veterinary care and a smaller number of expansive outdoor spaces compared to
            the city’s overall size. </p>
            <div className="anchor-section" id="rentals"></div>
            <h2><b>Cities With the Most
            Dog-Friendly Rentals, Veterinarians, &amp; Dog Parks</b></h2><p>For good measure, we zoomed in on
            individual ranking factors like the number of pet-friendly rental properties by city, the number of
            employed veterinarians by metropolitan statistical area (MSA), and the number of dog parks by city (all
            per 100,000 people). </p>
            <p>Atlanta, Orlando, and Austin hold the top three spots for the most
            dog-friendly rentals per 100,000 people while Raleigh, Denver, and Portland hold the top three spots for
            the most employed veterinarians per 100,000 people.</p>
            <div className="image-container mx-auto">
              <Img fluid={this.dogFriendlyRentals} alt="U.S. map showing the 10 cities with the most dog-friendly rentals per capita"  />
            </div>
            <div className="anchor-section" id="vets"></div>
            <div className="image-container mx-auto">
              <Img fluid={this.vetNearMe} alt=" U.S. map showing the 10 cities with the most veterinarians per capita"  />
            </div>
            <p>Portland, Tampa, San Francisco, and Salt Lake City have the highest number of dog parks per 100,000
              people for our four-legged friends. As the second-best U.S. city to be a dog overall, Portland, in
              particular, is celebrated for its off-leash green spaces and lush nature parks. The Northwestern city
              has 94 hiking trails nearby, as well as 152 miles of trail, according to <a
                href="https://www.hikingproject.com/directory/8014939/portland" rel="noreferrer"
                target="_blank">Hikingproject.com.</a></p>
            <div className="anchor-section" id="parks"></div>
            <div className="image-container mx-auto">
              <Img fluid={this.dogParksNearMe} alt="U.S. map showing the 10 cities with the most dog parks per capita"  />
            </div>
            <h2 id="h-full-data"><b>Full Data</b></h2><p>That wraps up our city ranking of the best (and
            worst) U.S. cities to<b> </b>be a dog. Interested in diving deeper into the numbers for the top 20
            cities, or wanting to see how your city stacks up if it’s not listed within the above maps?&nbsp;</p>
            <p>We’ve compiled our full data study for all 50 U.S. cities analyzed into <a
              href="https://public.flourish.studio/visualisation/4943050/" rel="noreferrer" target="_blank">this
              interactive data table</a><b><u>.</u></b> Search for the city you call home or click on the heading of
              each column to sort by that category!</p>

            <div className="flourish-embed flourish-table" data-src="visualisation/4943050"></div>
          </div>
        </section>

      </Layout>
    )
  }
}

export default friendlyCitiesPage

export const friendlyCitiesPageQuery = graphql`
  query friendlyCitiesPageQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    ogImage: file(relativePath: { eq: "landing-pages/og-image-friendly-cities.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mostDogFriendlyCities: file(relativePath: { eq: "landing-pages/most_dog_friendly_cities.png" }) {
      childImageSharp {
        fluid (maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bestCitiesForDogs: file(relativePath: { eq: "landing-pages/HP_BestCitiesToBeADog_1.png" }) {
      childImageSharp {
        fluid (maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    worstCitiesForDogs: file(relativePath: { eq: "landing-pages/HP_BestCitiesToBeADog_2.png" }) {
      childImageSharp {
        fluid (maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dogFriendlyRentals: file(relativePath: { eq: "landing-pages/HP_BestCitiesToBeADog_3.png" }) {
      childImageSharp {
        fluid (maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vetNearMe: file(relativePath: { eq: "landing-pages/HP_BestCitiesToBeADog_4.png" }) {
      childImageSharp {
        fluid (maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dogParksNearMe: file(relativePath: { eq: "landing-pages/HP_BestCitiesToBeADog_5.png" }) {
      childImageSharp {
        fluid (maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`